
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPageCurriculumVitaeLayout} from 'layouts/CmsPageCurriculumVitaeLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPageCurriculumVitaeLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Sono un appassionato `}<strong parentName="p">{`sviluppatore web full-stack`}</strong>{` che cerca di stare sempre al passo coi tempi nel campo IT.`}</p>
    <p>{`Ho lavorato come libero professionista, creando `}<strong parentName="p">{`software`}</strong>{` e `}<strong parentName="p">{`siti web`}</strong>{` `}<strong parentName="p">{`su misura`}</strong>{`. Questo mi permetteva di scegliere le tecnologie migliori per i progetti ai quali lavoravo, dandomi inoltre la possibilità di sperimentare.`}</p>
    <p>{`Ho applicato le architetture `}<a parentName="p" {...{
        "href": "https://jamstack.org/",
        "target": "_blank",
        "rel": "noopener"
      }}><strong parentName="a">{`JamSTACK`}</strong></a>{` e `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Serverless_computing",
        "target": "_blank",
        "rel": "noopener"
      }}><strong parentName="a">{`serverless`}</strong></a>{` programmando in `}<strong parentName="p">{`TypeScript`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`front-end statici e responsive`}</strong>{` basati su `}<strong parentName="li">{`React`}</strong>{` e `}<strong parentName="li">{`RxJS`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`back-end Node.js`}</strong>{` basati su `}<strong parentName="li">{`ExpressJS`}</strong>{` che girano sulle piattaforme `}<strong parentName="li">{`AWS Lambda`}</strong>{` e `}<strong parentName="li">{`Google Cloud Functions`}</strong></li>
    </ul>
    <h2>{`Esperienze di lavoro`}</h2>
    <h3>{`Sviluppatore web full-stack, 2021`}</h3>
    <h4>{`Klarna Italy, Milano, Italia`}</h4>
    <p>{`In Klarna ho approfondito la metodologia di `}<strong parentName="p">{`sviluppo software Agile`}</strong>{` implementando `}<strong parentName="p">{`Scrum`}</strong>{`.`}</p>
    <p>{`Ho avuto l’opportunità di `}<strong parentName="p">{`lavorare in team`}</strong>{` differenti che collaborano quotidianamente, grazie alla sua realtà strutturata da grande `}<strong parentName="p">{`multinazionale`}</strong>{`.`}</p>
    <p>{`Ho lavorato estensivamente su `}<strong parentName="p">{`front-end React`}</strong>{` e `}<strong parentName="p">{`microservizi Node.js`}</strong>{`.`}</p>
    <h5>{`Mansioni presso Klarna Italy`}</h5>
    <ul>
      <li parentName="ul">{`progettazione e sviluppo di un’`}<strong parentName="li">{`estensione per il browser`}</strong></li>
      <li parentName="ul">{`progettazione e sviluppo di `}<strong parentName="li">{`app iOS e Android`}</strong></li>
      <li parentName="ul">{`progettazione e sviluppo di `}<strong parentName="li">{`microservizi`}</strong></li>
    </ul>
    <h3>{`Sviluppatore web full-stack, 2014 – 2021`}</h3>
    <h4>{`Libero Professionista, Vicenza, Italia`}</h4>
    <p>{`Ho iniziato lavorare come `}<strong parentName="p">{`sviluppatore web libero professionista`}</strong>{` grazie a una rete di contatti che mi sono costruito durante un soggiorno di due anni a Melbourne, in Australia.`}</p>
    <p>{`Il mio primo progetto, al quale sto lavorando tuttora, è basato sul `}<strong parentName="p">{`framework PHP MVC Yii`}</strong>{` e gira su tre `}<strong parentName="p">{`istanze AWS EC2 replicate`}</strong>{` affiancate a un `}<strong parentName="p">{`database MongoDB`}</strong>{`, anch’esso replicato sui medesimi server. Ogni componente di questa applicazione è un `}<strong parentName="p">{`container Docker`}</strong>{` creato da `}<strong parentName="p">{`docker-compose`}</strong>{`.`}</p>
    <p>{`Lavorando a questo primo progetto ho capito che la mia specializzazione necessitava di un upgrade.`}</p>
    <p>{`Al tempo in cui `}<strong parentName="p">{`Node.js`}</strong>{` stava diventando molto popolare e nuovi e interessanti framework come `}<strong parentName="p">{`React`}</strong>{`, `}<strong parentName="p">{`Angular`}</strong>{` e `}<strong parentName="p">{`RxJS`}</strong>{` stavano emergendo, io facevo ancora affidamento a PHP e JQuery.`}</p>
    <p>{`Per questa ragione ho investito parte del mio tempo per imparare a sviluppare secondo gli ultimi trend tecnologici.`}</p>
    <p>{`Ho incontrato molti progetti interessanti lungo questo percorso, i quali mi hanno permesso di allargare la mia specializzazione e di aprire la mia mente.`}</p>
    <p>{`Per questo considero fondamentale per uno sviluppatore software non smettere mai di imparare e di soddisfare le proprie curiosità, dedicando parte del proprio tempo allo studio.`}</p>
    <h5>{`Scala e il Play Framework`}</h5>
    <p>{`Sperimentare con `}<strong parentName="p">{`Scala`}</strong>{` mi ha aiutato ad abbracciare la `}<strong parentName="p">{`programmazione funzionale`}</strong>{`, evolvendo il mio stile di sviluppo basato sulla tradizionale `}<strong parentName="p">{`programmazione OOP`}</strong>{`.`}</p>
    <p>{`Scala mi ha permesso inoltre di approfondire la mia conoscenza di tutta la `}<strong parentName="p">{`piattaforma JVM`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Java`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Kotlin`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Android`}</strong></li>
      <li parentName="ul">{`le tecnologie portate alla luce da `}<strong parentName="li">{`Lightbend`}</strong>{`, prima conosciuta come Typesafe, come `}<strong parentName="li">{`Akka`}</strong>{`, il `}<strong parentName="li">{`Play Framework`}</strong>{` e il `}<strong parentName="li">{`Lagom Framework`}</strong></li>
      <li parentName="ul">{`l’`}<strong parentName="li">{`architettura Microservice`}</strong></li>
      <li parentName="ul">{`la sintassi `}<strong parentName="li">{`MessageFormat`}</strong>{` per l’internazionalizzazione`}</li>
    </ul>
    <h5>{`Il framework front-end Cycle.js e RxJS`}</h5>
    <p>{`Amo Cycle.js in quanto mi ha permesso di sperimentare con l’`}<strong parentName="p">{`Observable Pattern`}</strong>{`, i `}<strong parentName="p">{`Reactive Streams`}</strong>{`, e di acquisire dimestichezza con la `}<strong parentName="p">{`Reactive Functional Programming`}</strong>{` con JavaScript.`}</p>
    <p>{`Grazie a Cycle.js ho scoperto `}<strong parentName="p">{`RxJS`}</strong>{`, il quale ha cambiato il modo in cui gestisco le chiamate `}<strong parentName="p">{`API REST`}</strong>{` e le `}<strong parentName="p">{`comunicazioni real-time`}</strong>{` lato front-end.`}</p>
    <p>{`Il `}<strong parentName="p">{`pattern Mode-View-Intent`}</strong>{` che Cycle.js suggerisce di seguire è intuitivo e permette di scrivere codice pulito, leggibile, strutturato e manutenibile.`}</p>
    <p>{`Cycle.js mi ha attirato fortemente, tanto da averlo preso in considerazione per lo sviluppo dei miei progetti front-end; purtroppo la scarsità della sua community mi fece desistere dall’adottarlo.`}</p>
    <h5>{`Serverless computing`}</h5>
    <p>{`Conosco profondamente `}<strong parentName="p">{`Linux`}</strong>{` e `}<strong parentName="p">{`FreeBSD`}</strong>{`; ciononostante considero la manutenzione di server inutilmente onerosa.`}</p>
    <p>{`Il `}<a parentName="p" {...{
        "href": "https://serverless.com/",
        "target": "_blank",
        "rel": "noopener"
      }}><strong parentName="a">{`Serverless Framework`}</strong></a>{` mi permette invece di focalizzarmi maggiormente sullo sviluppo, delegando le attività di amministrazione di sistema ai cloud provider.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`AWS Lambda`}</strong>{` con `}<strong parentName="li">{`AWS RDS`}</strong>{` come database e `}<strong parentName="li">{`AWS IoT`}</strong>{` per le comunicazioni real-time`}</li>
      <li parentName="ul"><strong parentName="li">{`Google Cloud Functions`}</strong>{` con `}<strong parentName="li">{`Cloud SQL`}</strong>{`, `}<strong parentName="li">{`Firebase`}</strong>{` or `}<strong parentName="li">{`Firestore`}</strong>{` come database e `}<strong parentName="li">{`Firebase Cloud Messaging`}</strong>{` per le comunicazioni real-time`}</li>
    </ul>
    <h5>{`Server in cloud`}</h5>
    <p>{`Pubblico progetti su `}<strong parentName="p">{`server in cloud`}</strong>{` quando non è possibile fare affidamento sul serverless computing.`}</p>
    <p>{`Impiego `}<strong parentName="p">{`istanze AWS EC2`}</strong>{`, `}<strong parentName="p">{`Vultr`}</strong>{` o `}<strong parentName="p">{`DigitalOcean`}</strong>{` a seconda delle necessità.`}</p>
    <p>{`La distribuzione Linux `}<a parentName="p" {...{
        "href": "https://nixos.org/",
        "target": "_blank",
        "rel": "noopener"
      }}><strong parentName="a">{`NixOS`}</strong></a>{` facilita la gestione dei miei server in cloud, i quali condividono una configurazione comune dichiarativa e funzionale.`}</p>
    <h5>{`Docker`}</h5>
    <p>{`Tutti sappiamo come `}<strong parentName="p">{`Docker`}</strong>{` ci permetta di creare ambienti di esecuzione prevedibili.`}</p>
    <p>{`Uso Docker e `}<strong parentName="p">{`docker-compose`}</strong>{` quando pubblico su server in cloud.`}</p>
    {
      /* Durante il mio studio di Docker, mi permisi di sperimentare con **Kubernetes** e **Helm**. */
    }
    <h5>{`React, Redux, RxJS and GatsbyJS`}</h5>
    <p>{`Ho scoperto `}<strong parentName="p">{`GatsbyJS`}</strong>{` mentre ispezionavo vari generatori di siti statici, come `}<strong parentName="p">{`Jekyll`}</strong>{`, `}<strong parentName="p">{`Hugo`}</strong>{`, `}<strong parentName="p">{`Hexo`}</strong>{` e `}<strong parentName="p">{`Metalsmith`}</strong>{`.`}</p>
    <p>{`GatsbyJS è esattamente ciò di cui ho bisogno in quanto crea applicazioni React ottimizzate.`}</p>
    <p><strong parentName="p">{`React`}</strong>{` è il framework che ho scelto di adottare dopo aver valutato attentamente curve di apprendimento, dimensioni, prestazioni e grandezza delle community.`}</p>
    <p>{`Lavorare con GatsbyJS mi ha permesso di approfondire la mia conoscenza con lo sviluppo React:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Redux`}</strong>{` per la gestione dello stato; lo trovo semplice e intuitivo considerando il mio approccio di Functional Reactive Programming appreso con Scala e Cycle.js`}</li>
      <li parentName="ul"><strong parentName="li">{`Emotion`}</strong>{` come libreria `}<strong parentName="li">{`CSS-in-JS`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`react-intl`}</strong>{` e la sintassi `}<strong parentName="li">{`MessageFormat`}</strong>{` per l’internazionalizzazione`}</li>
      <li parentName="ul"><strong parentName="li">{`RxJS`}</strong>{` per le chiamate `}<strong parentName="li">{`API REST`}</strong>{` e per `}<strong parentName="li">{`comunicazioni real-time`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`TypeScript`}</strong></li>
    </ul>
    <h5>{`Node.js ed ExpressJS`}</h5>
    <p>{`Partendo da un background in PHP, ho trovato qualsiasi altra piattaforma di sviluppo molto attraente.`}</p>
    <p>{`Ho sperimentato prima con `}<strong parentName="p">{`Rails`}</strong>{` in `}<strong parentName="p">{`Ruby`}</strong>{`, poi `}<strong parentName="p">{`Play Framework`}</strong>{` e `}<strong parentName="p">{`AKKA HTTP`}</strong>{` in `}<strong parentName="p">{`Scala`}</strong>{`.`}</p>
    <p>{`Ho infine scelto di adottare `}<strong parentName="p">{`Node.js`}</strong>{` come piattaforma di sviluppo back-end per vari motivi:`}</p>
    <ul>
      <li parentName="ul">{`community ampia`}</li>
      <li parentName="ul">{`possibilità di programmare con un linguaggio tipizzato grazie a TypeScript`}</li>
      <li parentName="ul">{`performance ottimali grazie a V8`}</li>
      <li parentName="ul">{`supporto nelle principali piattaforme di serverless computing: AWS Lambda e Google Cloud Functions`}</li>
      <li parentName="ul">{`codice riutilizzabile tra front-end e back-end`}</li>
      <li parentName="ul">{`linguaggio di programmazione comune tra front-end e back-end`}</li>
    </ul>
    <p>{`I back-end che sviluppo sono applicazioni `}<strong parentName="p">{`ExpressJS`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`codice TypeScript`}</li>
      <li parentName="ul">{`compilazione in JavaScript con `}<strong parentName="li">{`Webpack`}</strong></li>
      <li parentName="ul">{`autenticazione con `}<strong parentName="li">{`Passport.js`}</strong></li>
      <li parentName="ul">{`autenticazione e autorizzazioni `}<strong parentName="li">{`OAuth2`}</strong>{` con `}<strong parentName="li">{`oauth2orize`}</strong></li>
      <li parentName="ul">{`deploy con Serverless Framework`}</li>
      <li parentName="ul">{`test con `}<strong parentName="li">{`Jest`}</strong></li>
    </ul>
    <h3>{`Sviluppatore web full-stack, 2012 – 2014`}</h3>
    <h4>{`1form Online Pty Ltd, Melbourne, Australia`}</h4>
    {
      /* Ho lavorato prima come libero professionista e poi come impiegato a tempo indeterminato con un visto lavorativo. */
    }
    <p>{`Ho contribuito al rifacimento e alla modernizzazione di `}<a parentName="p" {...{
        "href": "https://1form.com",
        "target": "_blank",
        "rel": "noopener"
      }}>{`1form`}</a>{`, l’applicazione web più usata in Australia e Nuova Zelanda per inoltrare domande di locazione online. 1form vantava più di tre milioni di utenti registrati nel 2018.`}</p>
    <p>{`Ho lavorato per 1form prima come libero professionista, poi con un contratto a tempo pieno con un visto `}<strong parentName="p">{`457 Temporary Work (Skilled)`}</strong>{`.`}</p>
    <p>{`1form è nata come start-up, fondata da Chris Koch e Chad Stephens; due attenti imprenditori che hanno trovato fortuna nel lanciare velocemente questo importante servizio online.`}</p>
    <p>{`REA Group, compagnia leader del settore immobiliare australiano, `}<a parentName="p" {...{
        "href": "https://www.businessinsider.com.au/young-australian-entrepreneurs-sell-online-renters-app-for-15-million-2014-1",
        "target": "_blank",
        "rel": "noopener"
      }}>{`ha acquisito 1form nel 2014`}</a>{`.`}</p>
    <p>{`La grandezza del progetto ha richiesto un’attenta progettazione e strutturazione del codice per facilitare test e altre tecniche di `}<strong parentName="p">{`agile development`}</strong>{`.`}</p>
    <p>{`Dal secondo anno seguivo 1form quasi autonomamente:`}</p>
    <ul>
      <li parentName="ul">{`sviluppo`}</li>
      <li parentName="ul">{`test`}</li>
      <li parentName="ul">{`beta test`}</li>
      <li parentName="ul">{`deployment`}</li>
      <li parentName="ul">{`amministrazione di server e database`}</li>
    </ul>
    <h5>{`Mansioni presso 1form Pty Ltd`}</h5>
    <ul>
      <li parentName="ul">{`rifacimento del vecchio `}<em parentName="li">{`1form.com.au`}</em>{` e lancio come `}<em parentName="li">{`1form.com`}</em>{`, con una base solida costruita sulla base del framework `}<a parentName="li" {...{
          "href": "https://www.yiiframework.com/",
          "target": "_blank",
          "rel": "noopener"
        }}><strong parentName="a">{`Yii`}</strong></a></li>
      <li parentName="ul">{`sviluppo con HTML 5, CSS, SASS, JavaScript, PHP e MySQL`}</li>
      <li parentName="ul">{`project management, bug e time tracking con `}<strong parentName="li">{`JIRA`}</strong></li>
      <li parentName="ul">{`analisi dei requisiti e progettazione`}</li>
      <li parentName="ul">{`cooperazione con i reparti QA e servizio clienti per risolvere bug e migliorare funzionalità`}</li>
      <li parentName="ul">{`amministrazione dei server e degli ambienti di sviluppo`}</li>
    </ul>
    <h3>{`Sviluppatore web, 2012`}</h3>
    <h4>{`Univex, Melbourne, Australia`}</h4>
    <p>{`È stato il mio primo lavoro come libero professionista nel campo IT in Australia.`}</p>
    <p>{`Ho lavorato a un’applicazione web preesistente, la quale visualizzava contenuti pubblicitari digitali su dispositivi remoti, comunicando con un server centralizzato scritto in Python.`}</p>
    <p>{`Ho contribuito inoltre alla creazione di una nuova applicazione web gestionale per saloni di bellezza che accettava anche pagamenti POS; questa applicazione era basata sul framework PHP `}<strong parentName="p">{`Yii`}</strong>{`.`}</p>
    <h5>{`Mansioni presso Univex`}</h5>
    <ul>
      <li parentName="ul">{`manutenzione di un’applicazione web preesistente scritta in Python`}</li>
      <li parentName="ul">{`implementazione di una base solida per una nuova applicazione web estensibile`}</li>
      <li parentName="ul">{`progettazione di un database SQL`}</li>
      <li parentName="ul">{`project management, bug e time tracking con `}<strong parentName="li">{`JIRA`}</strong></li>
    </ul>
    <h3>{`Sviluppatore web full-stack, 2009 – 2011`}</h3>
    <h4>{`E-side, Vicenza, Italia`}</h4>
    <p>{`Presso E-side `}<strong parentName="p">{`ho affermato la mia esperienza come sviluppatore web`}</strong>{`.`}</p>
    <p>{`Ho lavorato a molteplici `}<strong parentName="p">{`siti web`}</strong>{` usando HTML, CSS, PHP, MySQL, JavaScript, JQuery, MooTools.`}</p>
    <p>{`Considerando il periodo, ho dovuto sviluppare tecniche efficaci per implementare siti web cross-browser, focalizzandomi soprattutto sui problemi di Internet Explorer (v6 e successive).`}</p>
    <p>{`Ho avuto l’opportunità di costruire siti web complessi basati su un’attenta progettazione del database attraverso l’implementazione di indici, trigger e funzioni personalizzate.`}</p>
    <p>{`Ho approfondito la mia conoscenza di `}<strong parentName="p">{`MySQL`}</strong>{` e imparato a scrivere query `}<strong parentName="p">{`SQL avanzate e ottimizzate`}</strong>{`.`}</p>
    <h5>{`Mansioni presso E-side`}</h5>
    <ul>
      <li parentName="ul">{`sviluppo web`}</li>
      <li parentName="ul">{`funzionalità avanzate di MySQL come trigger e stored procedure`}</li>
      <li parentName="ul">{`sviluppo cross-browser`}</li>
    </ul>
    <h3 id="lavoro-studio-storti">Sviluppatore web full-stack, 2007 &ndash; 2008</h3>
    <h4>{`Studio Storti, Vicenza, Italia`}</h4>
    <p>{`Questa è stata la mia prima occupazione come sviluppatore web.`}</p>
    <p>{`Sviluppavo applicazioni web open source per le pubbliche amministrazioni scritte in `}<strong parentName="p">{`PHP`}</strong>{`, affiancato da uno sviluppatore senior.`}</p>
    <p>{`Facevamo uso estensivo di ExtJS, una libreria JavaScript che facilitava lo sviluppo di front-end fornendo strumenti di GUI.`}</p>
    <h5>{`Mansioni presso Studio Storti`}</h5>
    <ul>
      <li parentName="ul">{`progettazione e sviluppo di applicazioni web con PHP, MySQL, HTML e JavaScript`}</li>
      <li parentName="ul">{`progettazione e amministrazione di database SQL`}</li>
      <li parentName="ul">{`esperienza pratica nel ciclo di sviluppo di software: definizione dei requisiti e analisi, progettazione, sviluppo, test e supporto`}</li>
    </ul>
    <h3>{`Stage come sviluppatore web full-stack, 2007`}</h3>
    <h4>{`Stage presso Studio Storti, Vicenza, Italia`}</h4>
    <p>{`Ho iniziato la mia esperienza con Studio Storti con uno stage professionale di sei mesi, seguiti da un contratto a tempo indeterminato.`}</p>
    <p>{`Leggi la sezione precedente per conoscere `}<a parentName="p" {...{
        "href": "#lavoro-studio-storti"
      }}>{`la mia esperienza lavorativa presso Studio Storti`}</a>{`.`}</p>
    <h2>{`Istruzione`}</h2>
    <h3>{`Perito informatico, 2001 – 2006`}</h3>
    <h4>{`ITIS G. Chilesotti, Vicenza, Italia`}</h4>
    <p>{`Ho scelto questo istituto guidato dalla passione per l’IT.`}</p>
    <p>{`Il piano di studi prevedeva due anni comuni a tutti gli istituti tecnici e tre anni di specializzazione in Informatica.`}</p>
    <p>{`Toccava argomenti quali `}<strong parentName="p">{`programmazione`}</strong>{`, `}<strong parentName="p">{`database`}</strong>{`, `}<strong parentName="p">{`sviluppo web`}</strong>{`, `}<strong parentName="p">{`reti`}</strong>{` e `}<strong parentName="p">{`amministrazione di sistema`}</strong>{` in ambienti Windows e Unix-like.`}</p>
    <p>{`Il corso mi ha fornito le basi della `}<strong parentName="p">{`programmazione procedurale e orientata agli oggetti`}</strong>{` con Pascal, Delphi con InterBase, C++ e Java.`}</p>
    <p>{`Dedicava inoltre spazio alle tecnologie web, in particolare HTML, JavaScript, PHP e MySQL.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    